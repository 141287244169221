import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import PacService, { Pac } from 'lib/api/pacApi';
import { Form, Input, Layout, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import Logo from 'components/Logo';
import { showErrorResponseNotification } from 'lib/helpers';
import privateKeysService from 'lib/api/privateKeysApi';
import { OnboardingStorageService } from 'lib/services/onboardingStorageService';
import { ApiKeyType } from 'lib/enums/apiKey';
import { IOnboardingStep1 } from 'lib/interfaces/onboarding';
import Button from 'components/Button/Button';
import Spin from 'components/Spin';
import { StyledQuill } from 'pages/PAC/styled';

import {
  StyledLogo,
  Wrapper,
  Sidebar,
  TopButtons,
  StyledSwitch,
  StyledText,
  FlexWrapper,
  FormWrapper,
} from './styled';

const { Content } = Layout;
const { Title } = Typography;

const Step1 = () => {
  const [form] = Form.useForm();
  const { user, logout } = useAuth0();
  const [isDataLoading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  const data = OnboardingStorageService.getStep1Data();

  const checkPac = async () => {
    try {
      const response = await PacService.get({
        search: user?.email,
      });
      const pacsThatCustomerOwns = response.data.results;
      const pacLite = pacsThatCustomerOwns.find((el: Pac) => el.type === 'lite');

      if (pacLite) {
        try {
          const apiKeys = await privateKeysService.get();
          OnboardingStorageService.setOnboardingResults({
            widgetUuid: pacLite?.widgetUuids[0],
            pacUuid: pacLite.uuid,
            publicApiKey:
              apiKeys.data.apiKeys?.find((k) => k.type === ApiKeyType.PUBLIC)?.key || '',
            optionUuid: pacLite?.widgets[0]?.topics[0]?.options[0].uuid,
          });
          navigate('/onboarding/step-3');
        } catch (error) {
          showErrorResponseNotification(error);
        }
      }
    } catch (error) {
      showErrorResponseNotification(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    checkPac();
  }, []);

  const onFinish = (values: IOnboardingStep1) => {
    OnboardingStorageService.setStep1Data({
      ...values,
      logoUrl: values.logoUrl === '' ? undefined : values.logoUrl,
    });
    navigate('/onboarding/step-2');
  };

  const onCancel = async () => {
    OnboardingStorageService.setStep1Data({
      title: '',
      description: '',
      widgetTitle: '',
      widgetDescription: '',
      logoUrl: undefined,
      doubleOptIn: true,
    });
    await logout({
      logoutParams: {
        returnTo: `${window.location.origin}/logout?onboarding=true`,
      },
    });
  };

  return (
    <Wrapper>
      <Sidebar>
        <StyledLogo>
          <Logo />
        </StyledLogo>
        <div>
          <StyledText>Setup your Preference Center</StyledText>
          <Title level={2}>Let&apos;s create a configuration</Title>
          <span>
            A configuration represents a complete setup of your Preference Center including the
            widget that allows your users to manage their consent decisions. This Preference Center
            is available as a stand-alone page and accessible anytime for end users via
            authentication with email flow.
          </span>
        </div>
      </Sidebar>
      {isDataLoading ? (
        <Spin />
      ) : (
        <Content style={{ padding: '40px', background: '#fff', overflow: 'scroll' }}>
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            initialValues={
              data || {
                title: '',
                description: '',
                widgetTitle: '',
                widgetDescription: '',
                logoUrl: '',
                doubleOptIn: true,
              }
            }
          >
            <Title level={2}>Create Configuration</Title>
            <TopButtons>
              <Button type="default" onClick={onCancel}>
                Cancel and Logout
              </Button>
              <Button type="primary" htmlType="submit" data-cy="button-nextStep">
                Next Step
              </Button>
            </TopButtons>
            <FlexWrapper>
              <div>
                <h3>Configuration Details</h3>
                <p>Add your configuration details here.</p>
              </div>
              <FormWrapper>
                <Form.Item
                  name="title"
                  label="Preference Center Title"
                  rules={[{ required: true, message: 'Please enter a title' }]}
                  data-cy="input-preferenceCenterTitle"
                >
                  <Input placeholder="Your Preference Center Title" />
                </Form.Item>
                <Form.Item
                  name="description"
                  label="Preference Center Description Text"
                  data-cy="input-preferenceCenterDescription"
                >
                  <StyledQuill
                    modules={{
                      toolbar: [['bold', 'italic', 'underline'], ['link'], ['clean']],
                    }}
                    formats={['bold', 'italic', 'underline', 'strike', 'link']}
                    placeholder="Specify description text for your Preference Management Center"
                    theme="snow"
                    style={{ fontSize: '14px' }}
                    data-cy="onboarding-pac-description-input"
                  />
                </Form.Item>
                <Form.Item
                  name="widgetTitle"
                  label="Consent Section Title"
                  rules={[{ required: true, message: 'Please enter consent section title' }]}
                  data-cy="input-consentTitle"
                >
                  <Input placeholder="Your Consent Section Title" />
                </Form.Item>

                <Form.Item
                  name="widgetDescription"
                  label="Consent Section Description Text"
                  data-cy="input-ConsentSectionCenterDescription"
                >
                  <StyledQuill
                    modules={{
                      toolbar: [['bold', 'italic', 'underline'], ['link'], ['clean']],
                    }}
                    formats={['bold', 'italic', 'underline', 'strike', 'link']}
                    placeholder="Specify description text for your Consent Widget"
                    theme="snow"
                    style={{ fontSize: '14px' }}
                    data-cy="onboarding-widget-description-input"
                  />
                </Form.Item>
                <Form.Item
                  name="logoUrl"
                  label="URL to Your Company Logo"
                  data-cy="input-companyLogo"
                  rules={[
                    {
                      type: 'url',
                      message: 'This field must be a valid url.',
                    },
                  ]}
                >
                  <Input placeholder="URL to company logo" />
                </Form.Item>

                <div style={{ display: 'flex', alignItems: 'baseline' }}>
                  <Form.Item
                    name="doubleOptIn"
                    valuePropName="checked"
                    data-cy="switch-doubleOptIn"
                  >
                    <StyledSwitch
                      checked={form.getFieldValue('doubleOptIn')}
                      onChange={(value) => form.setFieldValue('doubleOptIn', value)}
                      data-cy="configuration-new-widget-double-opt-in"
                    />
                  </Form.Item>
                  <span style={{ marginLeft: '10px' }}>
                    Activate Double-Opt-In for submission (recommended)
                  </span>
                </div>
              </FormWrapper>
            </FlexWrapper>
          </Form>
        </Content>
      )}
    </Wrapper>
  );
};

export default Step1;
