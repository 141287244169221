import styled from 'styled-components';
import ReactQuill from 'react-quill';

export const PageTitle = styled.h1`
  padding: 32px 40px;
  font-size: 24px;
  color: ${(props) => props.theme.colors.primaryText};
  font-weight: bold;
`;

export const PageWrapper = styled.div`
  padding: 40px;
  padding-top: 0px;
  width: 100%;
`;

export const Title = styled.h2`
  font-weight: 600;
  font-size: 16px;
  color: ${(props) => props.theme.colors.primaryText};
  margin-bottom: 10px;
`;

export const TitleWrapper = styled.div`
  padding: 12px 16px;
  border-bottom: 1px solid ${(props) => props.theme.colors.gray};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: 10px;

  & p {
    margin-top: 0px;
    margin-bottom: 0;
  }
`;

export const ButtonsWrapper = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 10;
  width: 232px;
  padding: 20px;

  & a {
    text-align: center;
    min-width: 100%;
    border: 2px solid ${(props) => props.theme.colors.border};
    color: black;
    border-radius: 4px;
    padding: 5px;
    display: block;
  }

  & button {
    width: 100%;
    margin-top: 20px;
    font-weight: 400;
  }
`;

export const TranslationButton = styled.button`
  border: none;
  background-color: transparent;
  position: absolute;
  right: 16px;
  cursor: pointer;
  color: ${(props) => props.theme.colors.primary};
  z-index: 5;
  display: flex;
  align-items: center;

  & span {
    margin-right: 5px;
  }
  & svg {
    height: 15px;
  }
`;

export const StyledQuill = styled(ReactQuill)`
  background-color: white;

  .ql-editor > p {
    color: rgb(48, 48, 48);
    font-size: 14px;
    font-weight: 400;
  }
`;
