import { Switch } from 'antd';
import styled from 'styled-components';

export const StyledSwitch = styled(Switch)`
  background-color: ${(props) => props.theme.colors.silver};
  min-width: 40px;
  height: 20px;

  &.ant-switch-checked {
    background-color: ${(props) => props.theme.colors.primary};

    & .ant-switch-handle {
      left: calc(100% - 18px);
    }
  }

  & .ant-switch-handle {
    width: 16px;
    height: 16px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  height: 100vh;
`;

export const StyledLogo = styled.div`
  position: absolute;
  top: 40px;
  left: 40px;
`;

export const Sidebar = styled.div`
  flex: 0 0 400px;
  background-color: ${(props) => props.theme.colors.lightBlue};
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
`;

export const StyledText = styled.p`
  font-weight: bold;
  font-size: 15px;
  color: ${(props) => props.theme.colors.primary};
`;

export const FlexWrapper = styled.div`
  display: flex;
`;

export const FormWrapper = styled.div`
  background-color: #f0f2f5;
  padding: 20px;
  margin-left: 20px;
  width: 450px;
`;

export const TopButtons = styled.div`
  position: absolute;
  top: 40px;
  right: 40px;
  display: flex;
  gap: 10px;
`;
